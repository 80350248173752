#button1 {
    background-color: darkblue;
    padding: 10px;
    width: 150px;
    color:white;
    border-radius: 10px;
    border-color: white
  }
  
#button2 {
    background-color: darkblue;
    padding: 10px;
    width: 150px;
    color:white;
    border-radius: 10px;
    border-color: white
  }
  
  